<template>
  <div class="Statistics">
    <div class="top-title">
      <div class="top-text">统计</div>
    </div>
    <div class="tongji">
            <div class="tongji-li">
                  <img src="../../../assets/image/tongji1.png" alt="">
                  <p>会员总注册量：<span>{{listpar.worker_total}}</span></p>
                  <p>会员月注册量：<span>{{listpar.worker_month_total}}</span></p>
                  <p>会员日注册量：<span>{{listpar.worker_today_total}}</span></p>
            </div>
            <div class="tongji-li">
                  <img src="../../../assets/image/tongji2.png" alt="">
                  <p>商家总注册量：<span>{{listpar.servicer_total}}</span></p>
                  <p>超级会员总量：<span>{{listpar.worker_super_total}}</span></p>
                  <p>经理总量：<span>{{listpar.worker_manager_total}}</span></p>
            </div>
            <div class="tongji-li">
                  <img src="../../../assets/image/tongji3.png" alt="">
                  <p>员工工时量：<span>{{listpar.workhours_total}}</span></p>
                  <p>员工月工时量：<span>{{listpar.workhours_month_total}}</span></p>
                  <p>上一日工时量：<span>{{listpar.workhours_yestoday_total}}</span></p>
            </div>
            <div class="tongji-li">
                  <img src="../../../assets/image/tongji4.png" alt="">
                  <p>总收益：<span>{{listpar.income_total}}</span></p>
                  <p>月收益：<span>{{listpar.income_month_total}}</span></p>
                  <p>上一日收益：<span>{{listpar.income_yestoday_total}}</span></p>
            </div>
            <div class="tongji-li">
                  <img src="../../../assets/image/tongji5.png" alt="">
                  <p>会员黑名单：<span>{{listpar.servicer_black_total}}</span></p>
                  <p>商家黑名单：<span>{{listpar.worker_black_total}}</span></p>
                  <p>用户违规人数：<span>{{listpar.worker_break_total}}</span></p>
                  <p>商家违规人数：<span>{{listpar.servicer_break_total}}</span></p>
            </div>

    </div>

    <div class="zhuzhuangtu" v-if="flag">
        
            <Examples :listpar="listpar" />
    </div>
    
  </div>
</template>


<script>
import Examples from "../../../components/examples/examples.vue";
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js";
export default {
  name:"Statistics",
  components: {
    Examples,
  },

  data() {
    return {
      listpar: {},
      flag:false,
    };
  },

  created() {
    this.getSong();
  },
  mounted() {},

  methods: {

    //查询接口
      async getSong(){
				let res = await apiurl.entityDataHandle({
        FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
        loginName:this.$common.baseGetuSername(),//登录账号 
        loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'StatisticsEntity',
        handleMode:'fetchall',
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.listpar = res.Result;
          this.flag = true
      },

  },
  computed: {},
};
</script>



<style  scoped>
.tongji{
  width: 94%;
  height: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
}

.tongji-li{
  text-align: center;
}
.tongji-li img{
  width: 50px;
  height: 50px;
  margin: 0 auto;
}
.tongji-li p{
  text-align: left;
  color: #777;
  font-size: 14px;
  padding-top: 30px;
}
.tongji-li p span{
  color: #555;
  font-weight: 600;
  font-size: 15px;
}


.zhuzhuangtu{
  width: 100%;
  height: 500px;
}
@import "../../../assets/css/liststyle.css";
</style>