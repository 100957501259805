<template>
  <div class="">
    <div class="zzg">
      <div>
        <p class="zzg-p">经理收益前10排名</p>
 <div  div style="width: 600px; height: 400px" ref="chart"></div>
      </div>
       
        <div>
          <p class="zzg-p">超级会员收益前10排名</p>
<div style="width: 600px; height: 400px" ref="charts"></div>
        </div>
        
    </div>
  </div>
</template>



<script>
import echarts from "echarts";
let Echarts = require("echarts/lib/echarts"); //基础实例 注意不要使用import
require("echarts/lib/chart/bar"); //按需引入 bar = 柱状图

export default {
  data() {
    return {
      chart: null,
      charts: null,
      // 经理
      managername:[],
      managermoney:[],
      // 超级会员
      supername:[],
      supermoney:[],
    };
    
  }, //图表实例
  props:["listpar"],
  mounted() {
    // console.log(this.listpar);

         // 经理转化为新数组
     this.managername = this.listpar.manager_income_top10.map( (item, index) => {
     	return item.true_name
     })
     this.managermoney = this.listpar.manager_income_top10.map( (item, index) => {
     	return item.money_total
     })

         // 超级会员转化为新数组
     this.supername = this.listpar.super_income_top10.map( (item, index) => {
     	return item.true_name
     })
     this.supermoney = this.listpar.super_income_top10.map( (item, index) => {
     	return item.money_total
     })




    this.init();
    this.inits();

  },
  methods: {

    init() {
      //2.经理初始化
      this.chart = Echarts.init(this.$refs.chart);

      //3.配置数据
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.managername,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "收益",
            type: "bar",
            barWidth: "60%",
            data: this.managermoney,
          },
        ],
      };
      // 4.传入数据
      this.chart.setOption(option);
    },
    // 
    inits() {
      //2.超级会员初始化
      this.charts = Echarts.init(this.$refs.charts);

      //3.配置数据
      let options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.supername,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "收益",
            type: "bar",
            barWidth: "60%",
            color:"#F08621",
            data: this.supermoney,
          },
        ],
      };
      // 4.传入数据
      this.charts.setOption(options);
    },
  },
};
</script>


<style  scoped>
.zzg{
   width: 100%;
  height: 500px;
  display: flex;
  justify-content: space-around;
  padding-top: 50px;
}
.zzg-p{
padding-left: 20px;
}
.zzg div:nth-of-type(1) .zzg-p{
    border-left: 3px solid rgba(0, 0, 255, 0.582);
}
.zzg div:nth-of-type(2) .zzg-p{
 border-left: 3px solid #F08621;
}

</style>